import React from "react"
import Layout from "../components/simpleLayout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"
import "../utils/css/components/home.css"
import siteConfig from '../../config'

const IndexPage = () => {

    return(
    <Layout>
        <SEO title="Podcasts by Asish George" keywords={siteConfig.keywords}/>
      
        <section className="container width">

            <h1 className="heading">Podcast</h1>


            <iframe src="https://open.spotify.com/embed-podcast/show/4tFrq9jspE2IS1LjupE4ap" title="spotify" width="100%" height="232" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>


            <br/> 

            <h2>Available in <a href={"https://anchor.fm/on-the-go"}>Anchor</a>, <a href={"https://www.breaker.audio/on-the-go-2"}>Breaker</a>,  
            <a href={"https://www.google.com/podcasts?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy80MDhiMDVjL3BvZGNhc3QvcnNz"}> Google Podcasts</a>,  
            <a href={"https://overcast.fm/itunes1509148534/on-the-go"}> Overcast</a>,  
            <a href={"https://pca.st/l32q13xd"}> Pocket Casts</a>, 
            <a href={"https://radiopublic.com/on-the-go-8QoBVl"}> Radio Public</a> and 
            <a href={"https://open.spotify.com/show/4tFrq9jspE2IS1LjupE4ap"}> Spotify</a>.


            </h2>
                    {/* <iframe src="https://anchor.fm/on-the-go/embed" height="102px" width="400px" scrolling="no"></iframe> */}


        </section>
    </Layout>
  )
}

export default IndexPage
